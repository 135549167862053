<template>
  <section class="pd-20">
    <aPageHeader class="pd-0">
      <template #title>
        <h1>
          <a-icon class="gradient" type="file" />
          Documentos
        </h1>
      </template>
      <template #extra>
        <a-button
          icon="file"
          type="primary"
          ghost
          @click="modalCreateDocument = true"
        >
          Novo Documento
        </a-button>
      </template>
    </aPageHeader>

    <aTabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <aTabPane key="1" tab="ATIVOS" force-render />
      <aTabPane key="0" tab="DESATIVADOS" force-render />
    </aTabs>

    <aCollapse
      class="travel-filters expandable mt-0 mb-10"
      :activeKey="0"
      expandIconPosition="right"
    >
      <aCollapsePanel :key="1">
        <template #header> <a-icon type="filter" /> FILTRAR </template>
        <aRow :gutter="20">
          <aCol :span="6">
            <div class="travel-input">
              <label class="filled">Nome </label>
              <a-input
                placeholder="Escreva aqui..."
                v-model="document.filters.searchTerm"
                @change="getDocuments()"
              />
            </div>
          </aCol>
        </aRow>
      </aCollapsePanel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="columns"
      :data-source="document.list"
      :loading="document.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="documentsTableChange"
    >
      <a slot="id" slot-scope="text">{{ text }}</a>

      <div slot="company" slot-scope="record">
        <div v-if="record.is_general == 1">---</div>
        <div v-if="record.is_general == 0">{{ record.company }}</div>
      </div>

      <div slot="company_branch" slot-scope="record">
        <div v-if="record.is_general == 1">---</div>
        <div v-if="record.is_general == 0">
          {{ record.company_branch.name }}
        </div>
      </div>

      <div slot="is_general" slot-scope="record">
        <a-icon class="f14 cgreen" v-if="record.is_general == 1" type="check" />

        <div v-if="record.is_general == 0">Não</div>
      </div>

      <div slot="status" slot-scope="record">
        <a-icon
          class="f18"
          v-if="record.status == 1"
          type="check-circle"
          theme="twoTone"
          two-tone-color="#52c41a"
        />
        <a-icon
          class="f18"
          v-if="record.status == 0"
          type="close-circle"
          theme="twoTone"
          two-tone-color="#f00"
        />
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }}
            {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <span class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a class="edit" @click="edit(record.id)">
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>

        <a-popconfirm
          title="Tem certeza que deseja duplicar?"
          ok-text="Sim"
          placement="left"
          cancel-text="Não"
          @confirm="duplicate(record.id)"
        >
          <a-icon slot="icon" type="question-circle-o" />
          <a-tooltip placement="top" title="Duplicar">
            <a class="ml-10 f18" style="color: #3498db">
              <a-icon type="copy" />
            </a>
          </a-tooltip>
        </a-popconfirm>
      </span>
    </aTable>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="document.pagination.page"
        :total="document.pagination.total"
        @change="changeDocumentsPage"
        @showSizeChange="changeDocumentsPageSize"
      />
    </div>

    <a-modal
      v-model="modalCreateDocument"
      :footer="null"
      title="NOVO DOCUMENTO"
      @ok="handleOk"
      @cancel="closeModal"
    >
      <a-form v-if="modalCreateDocument" :form="form" @submit="handleSubmit">
        <aRow>
          <aCol :span="24">
            <a-form-item class="travel-input-outer">
              <a-input
                placeholder="NOME DO MODELO"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Escreva um nome para o documento',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </aCol>

          <aCol class="a-right" :span="24">
            <a-button
              class="travel-btn green"
              :disabled="disableButton"
              html-type="submit"
            >
              Criar documento
            </a-button>
          </aCol>
        </aRow>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import documentMixins from "../../components/documents/mixins/documentMixins";
export default {
  components: {},
  mixins: [documentMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      modalCreateDocument: false,
      activeTab: "1",
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 80,
        },
        {
          title: "Nome do documento",
          dataIndex: "name",
          key: "name",
          width: 500,
        },
        {
          title: "Todas empresas",
          scopedSlots: { customRender: "is_general" },
          width: 140,
        },
        // {
        //     title: "Empresa",
        //     scopedSlots: { customRender: "company" },
        //     width: 140,
        // },
        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 140,
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          width: 140,
        },

        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          width: 160,
        },
        {
          title: "",
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.document.filters.status.selected = ["1"];
    this.getDocuments();
  },
  methods: {
    duplicate(id) {
      this.$http
        .post("/document/duplicate", {
          id: id,
          user_id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getDocuments();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    edit(id) {
      if (parseInt(id) > 86) this.$router.push(`edit/${id}`);
      if (parseInt(id) < 87) this.$router.push(`edit2/${id}`);
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.user = this.$store.state.userData.id;
        if (!err) {
          this.$http
            .post("/document/create", values)
            .then(({ data }) => {
              // this.$router.push(`edit2/${data.id}`);
              this.$router.push(`edit/${data.id}`);
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        }
      });
    },
  },
};
</script>
